<template>
  <div class="wrap">
    <div class="top-banner">
      <div class="w-1200">
        <div class="title">大数据分析平台</div>
        <div class="en">DU-BAP</div>
        <p>一站式体验、开箱即用、集群负载均衡</p>
        <p>高可用等行业领先理念进行设计</p>
      </div>
    </div>
    <div class="main-box w-1200">
			<!-- <div class="main-item">
				<div class="center-title">
					设计理念
				</div>
				<div class="idea-box">
					<div class="item">
						<div class="text">
							<p>技术架构</p>
							<p>产品使用主流B/S技术架构，按一站式体验、开箱即用、集群负载均衡、高可用等行业领先理念进行设计。</p>
						</div>
						<img src="~@/assets/images/product/idea-icon-1.png"/>
					</div>
					<div class="item">
						<div class="text">
							<p>日志挖掘技术</p>
							<p>通过内存引擎Trino，加速数据分析与处理效率；通过自主研发的权限管控体系，为用户提供便捷的数据资产使用与查询功能；通过自主研发的数据脱敏功能，为客户数据资产安全保驾护航。</p>
						</div>
						<img src="~@/assets/images/product/idea-icon-2.png"/>
					</div>
				</div>
			</div> -->
      <div class="main-item">
        <div class="title">功能介绍</div>
        <div class="content">
          <div class="function-box">
            <div class="function-item">
              <img src="~@/assets/images/product/function-icon-1.png"/>
              <label>元数据管理</label>
              <p>包含项目管理、系统管理、数据库管理等基础信息管理，以及元数据同步等功能。</p>
            </div>
            <div class="function-item">
              <img src="~@/assets/images/product/function-icon-2.png"/>
              <label>数据安全管理</label>
              <p>包含脱敏规则管理，数据资产字段分级分类管理、行级数据权限管理等数据安全管理功能。</p>
            </div>
            <div class="function-item">
              <img src="~@/assets/images/product/function-icon-3.png"/>
              <label>租户级资产管理</label>
              <p>包括租户级资产申请与管理。</p>
            </div>
            <div class="function-item">
              <img src="~@/assets/images/product/function-icon-4.png"/>
              <label>资产目录管理</label>
              <p>提供企业级数据字典的查询功能。</p>
            </div>
						<div class="function-item">
							<img src="~@/assets/images/product/function-icon-5.png"/>
							<label>私有资产管理</label>
							<p>可对用户自建表进行赋权分享给其他用户。</p>
						</div>
						<div class="function-item">
							<img src="~@/assets/images/product/function-icon-6.png"/>
							<label>流程中心</label>
							<p>平台提供数据资产的申请使用与审批流程功能。</p>
						</div>
						<div class="function-item">
							<img src="~@/assets/images/product/function-icon-7.png"/>
							<label>审计功能</label>
							<p>平台记录用户SQL查询记录，辅助审计。</p>
						</div>
						<div class="function-item">
							<img src="~@/assets/images/product/function-icon-8.png"/>
							<label>多终端使用</label>
							<p>平台实现一次授权多终端使用，如：Python、R、Dbeaver等终端，以及通过jdbc连接的系统。</p>
						</div>
          </div>
        </div>
      </div>
      <div class="main-item">
        <div class="title">产品架构</div>
        <div class="content">
          <img src="~@/assets/images/bap-architecture.png"/>
        </div>
      </div>
      <div class="main-item">
        <div class="title">产品优势</div>
        <div class="content">
          <div class="core-box">
            <div class="core-item">
              <img src="~@/assets/images/product/core-icon-1.png"/>
              <div class="text-box">
                <label>多种数据源</label>
                <p>支持主流的数据库，如：mysql、Oracle等传统数据库，Hive、es、Hbase、Clickhouse等大数据库，Gbase、达梦等国产数据库</p>
              </div>
            </div>
            <div class="core-item">
              <img src="~@/assets/images/product/core-icon-2.png"/>
              <div class="text-box">
                <label>跨库关联查询</label>
                <p>支持两个及以上的同类或异构数据库跨库关联查询</p>
              </div>
            </div>
						<div class="core-item">
							<img src="~@/assets/images/product/core-icon-3.png"/>
							<div class="text-box">
								<label>设备绑定</label>
								<p>安全加强功能，支持通过ip途径绑定用户IP，仅限特定IP使用</p>
							</div>
						</div>
						<div class="core-item">
							<img src="~@/assets/images/product/core-icon-4.png"/>
							<div class="text-box">
								<label>单元级安全机制</label>
								<p>可通过不同配置组合，实现同一数据库表，不同用户之间的差异化控制，如：列级数据脱敏，行级数据权限过滤</p>
							</div>
						</div>
						<div class="core-item">
							<img src="~@/assets/images/product/core-icon-1.png"/>
							<div class="text-box">
								<label>智能敏感内容识别</label>
								<p>对企业所有资产根据进行内容识别，进行自动化分级分类管理</p>
							</div>
						</div>
          </div>
        </div>
      </div>
      <!-- <div class="main-item">
        <div class="title">应用场景</div>
        <div class="content">
          <div class="scenario-box">
            <div class="scenario-item">
              <label>数据分析，面向企业精细化运营</label>
              <p>汇聚处理各类业务数据，基于中台强大的引擎能力完成复杂的分析与监控工作，灵活响应并满足新需求，实现对用户千人千面的精细化运营</p>
              <img src="~@/assets/images/rdc/scenario-1.png"/>
            </div>
            <div class="scenario-item">
              <label>数据治理，为企业数据保驾护航</label>
              <p>针对各种来源的数据，统一数据标准、统一数据资产，同时保障数据安全，从组织和个人角度，促进数据在组织内无障碍地进行交流</p>
              <img src="~@/assets/images/rdc/scenario-2.png"/>
            </div>
            <div class="scenario-item">
              <label>数据应用，助力企业数字化转型</label>
              <p>分析、管理数据，基于硬件和业务数据，完成企业的事前风险预测、事中监控调度和事后问题排查工作，保障生产和供应的可靠性</p>
              <img src="~@/assets/images/rdc/scenario-1.png"/>
            </div>
          </div>
        </div>
      </div> -->
    </div>
  </div>
</template>
<script>
export default {
  
}
</script>
<style lang="scss" scoped>
.wrap {
  background-color: #F7F8FA;
}
.top-banner {
  height: 320px;
  background-image: url('~@/assets/images/product/banner.png');
  background-repeat: no-repeat;
  background-position: center;
  .title {
    font-size: 40px;
    font-weight: 100;
    color: #FFFFFF;
    margin-bottom: 20px;
    padding-top: 82px;
  }
  .en {
    font-size: 40px;
    font-weight: 400;
    color: #FFFFFF;
    margin-bottom: 20px;
  }
  p {
    font-size: 18px;
    font-weight: 100;
    color: #FFFFFF;
    opacity: 0.8;
    margin-bottom: 14px;
  }
}
.main-box {
  .title {
    font-size: 20px;
    font-weight: 500;
    color: #4F5969;
    padding: 40px 0;
  }
	.center-title {
		text-align: center;
		font-size: 40px;
		color: #333;
		padding-top: 45px
	}
  .content {
    img {
      width: 1199px;
    }
  }
	.idea-box {
		margin-top: 50px;
		overflow: hidden;
		.item {
			background-color: #fff;
			border-radius: 5px;
			float: left;
			width: 590px;
			.text {
				float: left;
				p {
					padding-left: 30px;
					color: #1a2983;
					width: 258px;
					line-height: 1.3;
					&:first-child {
						padding-top: 30px;
						font-size: 28px;
						margin-bottom: 20px;
					}
				}
			}
			&:not(:last-child){
				margin-right: 19px;
			}	
			img {
				padding-left: 30px;
			}
		}
	}
  .function-box {
    overflow: hidden;
    .function-item {
      box-sizing: border-box;
      float: left;
      width: 285px;
      height: 244px;
      background: #FFFFFF;
      border-radius: 5px;
      padding: 30px;
      margin-right: 19px;
      >img {
        width: 26px;
      }
      &:nth-child(-n+4) {
        margin-bottom: 19px;
      }
      &:nth-child(4n){
        margin-right: 0;
      }
      label {
        display: block;
        margin-top: 20px;
        font-size: 24px;
        font-weight: 400;
        color: #1D2129;
      }
      p {
        margin-top: 22px;
        font-size: 16px;
        font-weight: 400;
        color: #87909C;
        line-height: 1.2;
      }
    }
  }
  .core-box {
    overflow: hidden;
    .core-item {
      padding: 40px 28px 40px 30px;
      float: left;
      box-sizing: border-box;
      width: 590px;
      height: 159px;
      background: #FFFFFF;
      border-radius: 5px;
			margin-bottom: 20px;
      >img {
        width: 72px;
      }
      &:nth-child(2n-1) {
        margin-right: 20px;
      }
      img {
        float: left;
      }
      .text-box {
        margin-left: 102px;
        label {
          font-size: 24px;
          font-weight: 400;
          color: #1D2129;
        }
        p {
          margin-top: 22px;
          font-size: 16px;
          font-weight: 400;
          color: #87909C;
          line-height: 1.2;
        }
      }
    }
  }
  .scenario-box {
    overflow: hidden;
    margin-bottom: 60px;
    .scenario-item {
      padding-top: 30px;
      float: left;
      width: 386px;
      background: #FFFFFF;
      border-radius: 5px;
      >img {
        width: 386px;
      }
      &:not(:last-child){
        margin-right: 19px;
      }
      label {
        font-size: 20px;
        font-weight: 400;
        color: #1D2129;
        padding: 30px 30px 0;
      }
      p {
        font-size: 16px;
        font-weight: 400;
        color: #87909C;
        margin-top: 22px;
        margin-bottom: 30px;
        padding: 0 30px;
        height: 77px;
        line-height: 1.2;
      }
    }
  }
}
</style>
